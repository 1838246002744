import React from 'react'

import Menu from '../components/Menu'

const Policy = () => {
  return (
    <>
    <Menu/>
    <table className="table_center_by_css">

  
    <tr className="table_tr_by_css">
      <td className="table_td_by_css"><h2>Политика конфиденциальности для приложения<br/> «Торговый дом «Хария»</h2></td>
    </tr>
	<tr className="table_tr_by_css" >
      		<td className="table_td_by_css"><h3>1. Основные термины</h3></td>
    </tr>
    <tr className="table_tr_by_css">
        <td>
            <h5>
                1.1. Политика – настоящая Политика конфиденциальности приложения «Торговый дом «Хария». <br/>
                1.2. Приложение – программное обеспечение «Торговый дом «Хария» разработанное под мобильные платформы iOS, Android или веб браузеры, предназначенное для установки и использования на смартфонах, планшетах и персональных компьютерах, 

                посредством которого осуществляется доступ к его функционалу.<br/> 
                1.3. Пользователь – любое лицо, использующее Приложение в соответствии с требованиями действующего законодательства РФ и настоящей Политики. <br/>
                1.4. Правообладатель – ООО «ТД Хария» (ОГРН: 1061683003117, ИНН: 1661015174), которому принадлежат исключительные права на Приложение. <br/>
                1.5. Устройство – мобильное техническое устройство или другое устройство, имеющее доступ к сети Интернет, на котором установлено Приложение. <br/>
                1.6. Персональные данные – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных). 
            </h5>
        </td>
    </tr>

    <tr className="table_tr_by_css">
        <td className="table_td_by_css"><h3>2. Цели обработки данных Пользователей Приложения </h3></td>
    </tr>


    <tr className="table_tr_by_css">
        <td>
            <h5>
                2.1 Настоящая Политика применима исключительно к отношениям, связанным с обработкой данных Пользователя в процессе использования им Приложения.<br/> 
                2.2. Правообладатель собирает данные технического характера об использовании Приложения и о возникающих ошибках с целью создания статистики пользования Приложением для последующего его улучшения и устранения ошибок.<br/> 
                2.3. В случае установки и использования Приложения на Устройство Пользователь соглашается с условиями настоящей Политики. Если Пользователь не согласен с условиями Политики или отдельные условия Политики ему не понятны, в таком случае 
                Пользователь обязан немедленно прекратить использование Приложения.
            </h5>
        </td>
    </tr>


    <tr className="table_tr_by_css">
        <td className="table_td_by_css">
            <h3>3. Перечень собираемых данных </h3>
        </td>
    </tr>

    <tr className="table_tr_by_css">
        <td>
            <h5>
                3.1. Технические данные, которые собирает Правообладатель: операционная система, платформа, данные о количестве запусков Приложения в целом и их отдельных экранов и функций. При наличии разрешения Пользователя на предоставление отчётов о 

                сбоях Правообладатель может собирать данные об ошибках: технические параметры Устройства, данные о локализации ошибки, частоте ошибок и сбоев.<br/>  
                3.2. Правообладатель не собирает личную информацию о Пользователе, не обрабатывает какие либо Персональные данные Пользователя и не осуществляет их хранение.<br/>  
                3.3. Пользователь имеет возможность отключить предоставление Правообладателю технических данных и данных об ошибках через меню настроек Устройства. 
            </h5>
        </td>
    </tr>

    <tr className="table_tr_by_css">
        <td className="table_td_by_css">
            <h3>4. Передача данных Пользователя, которые собирает и обрабатывает Правообладатель, третьим лицам </h3>
        </td>
    </tr>

    <tr className="table_tr_by_css">
        <td>
            <h5>
                Для осуществления сбора, обработки и хранения данных технического характера об использовании Приложения и о возникающих ошибках, Правообладатель может использовать сервисы третьих лиц. 
            </h5>
        </td>
    </tr>

    <tr className="table_tr_by_css">
        <td className="table_td_by_css">
            <h3>
                5. Безопасность данных Пользователя 
            </h3>
        </td>
    </tr>

    <tr className="table_tr_by_css">
        <td>
            <h5>
                Обработка данных Пользователя происходит в Приложении на его собственном Устройстве без участия Правообладателя. 
                Безопасность данных обеспечивается стандартными средствами операционной системы, в самом Приложении дополнительные средства защиты отсутствуют. 
            </h5>
        </td>    
    </tr>


    <tr className="table_tr_by_css">
        <td className="table_td_by_css">
            <h3>
                6. Заключительные положения 
            </h3>
        </td>
    </tr>

    <tr className="table_tr_by_css">
        <td>
            <h5>
                По вопросам работы Приложения просьба обращаться по адресу электронной почты: 5106894ttn@gmail.com. Правообладатель имеет право в любое время изменить Политику путём публикации новой редакции на официальном сайте Правообладателя: 
                http://haria.kleversoft.ru/. Данная редакция Политики действует от «01» октября 2024 года. Политика действует бессрочно до замены её новой редакцией. 
            </h5>
        </td>
    </tr>



</table>


    </>
  )
}

export default Policy