import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import Menu from '../components/Menu'
import axios from 'axios'
import { Link } from 'react-router-dom';

const Details = (props) => {
  let { id } = useParams();
  const [product, setProduct] = useState([]);
  const [country, setCountry] = useState('')

  const src =`https://admin.kleversoft.ru/api/product/${id}`

  useEffect(() => {
    axios.get(src)
    .then(({data}) => {
      console.log(data.country.title)
      setProduct(data);
      setCountry(data.country.title)
      
    }).catch(err => {
      console.log(err);
      // Alert.alert('Ошибка', 'Не удалось подключится к серверу');
    }).finally(() => {
    });
    console.log(product)
  }, [id]);

  return (
    <>
    {/* {product.coutnry.map(cont => <div>{cont.title}</div>)} */}
        <Menu/>
        <div>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <nav className="breadcrumbs">
            <ul>
              <li><Link to="/">Главная</Link></li>

              <li><span>{product.title}</span></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-5 col-lg-4 mb-3">
          <div className="bg-white p-3 h-100">
            <div id="carouselExampleFade" className="carousel carousel-dark slide carousel-fade">
              <div className="carousel-inner">
                <div v-if="product" className="carousel-item active">
                  <img src={product.preview_image} className="d-block w-100" alt="..."/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-7 col-lg-8 mb-3">
          <div className="bg-white product-content p-3 h-100">
            <h3 className="section-title"><span>{product.title}</span></h3>

            <div className="product-price">
              <div>{product.price} р.</div>
            </div>

            <p>{product.description}
            </p>


            <div className="row mt-3">
              <div className="col-lg-6">
                <div className="card h-100">
                  <div className="card-body">
                    <h5 className="card-title"><i className="fa-solid fa-list"></i> Характеристики</h5>
                    <ul className="list-unstyled">
                      <div v-if="product">

                        <li>Крепость {product.percent}%</li>
                        <li>Объем {product.volume}л</li>
                        <li>Страна происхождения {country}</li>
                      </div>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

    </div>


  </div>
    </>
  )
}

export default Details