import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import Menu from '../components/Menu'
import axios from 'axios'
import { Link } from 'react-router-dom';
import MiniCard from '../components/MiniCard'

const Products = (props) => {
  const src = 'https://admin.kleversoft.ru/api/product/filters';
  let { id } = useParams();
  const [catname, setCatname] = useState();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  // const [category, setCategory] = useState([]);
  const [minprice, setMinprice] = useState();
  const [maxprice, setMaxprice] = useState();




  useEffect(() => {
    axios.post(src, {
      'id': id
    })
    .then(({data}) => {
      setCatname(data.catname.title)
      setProducts(data.products)
      setCategories(data.categories)
      setMinprice(data.price.min);
      setMaxprice(data.price.max);
      // console.log(data.price);
      dipPrice();  

      
    }).catch(err => {
      // console.log(err);
      // Alert.alert('Ошибка', 'Не удалось подключится к серверу');
    }).finally(() => {
      // setIsLoading(false);
    });
  }, [id]);

  function dipPrice() {
    
  }

function filterClick() {
  // console.log('Категории' . category)
  const src = 'https://admin.kleversoft.ru/api/product';
  // const prices = [minprice, maxprice];

  axios.post(src, {
        'categories': '',
        'prices': [minprice, maxprice],
        'id': id,
        'page': 1
  }).then(({data}) => {
    // setCatname(data.catname.title)
    setProducts(data.data)
    // setCategories(data.categories)
    // setMinprice(data.price.min);
    // setMaxprice(data.price.max);
    console.log(data.data);
    // dipPrice();  

    
  }).catch(err => {
    // console.log(err);
    // Alert.alert('Ошибка', 'Не удалось подключится к серверу');
  }).finally(() => {
    // setIsLoading(false);
  });

  // const prices = [minprice, maxprice];
  // console.log(minprice, maxprice, categories, id, prices)
}


  return (
    <>
    <Menu/>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <nav className="breadcrumbs">
            <ul>
              <li><Link to="/" >Главная</Link></li>
              
              <li><span>{catname}</span></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-4">
          <div className="sidebar">
            <button className="btn btn-primary w-100 text-start collapse-filter-btn mb-3" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseFilters" aria-expanded="false" aria-controls="collapseFilters">
              <i className="fa-solid fa-filter"></i>Фильтры
            </button>

            <div className="collapse collapse-filters" id="collapseFilters">

              <div className="filter-block">
                {/* <form > */}
                  <div className="filter-block">
                    <h5 className="section-title mt-3"><span>Категории</span></h5>

                    {
                      categories.map((item) => {
                        if (item.count_prod > 0 ) {
                          return (
                        <div key={item.id} className="form-check d-flex justify-content-between">
                          <div>
                            <input id={item.id} value={item.id} className="form-check-input" type="checkbox" />
                            <label className="form-check-label">{item.title}</label>
                          </div>
                          
                          <span className="badge bg-primary rounded-circle">{item.count_prod}</span>
                        </div>)
                        }
                      }
                        
                        // {item.count_prod &&
                        
                        // }
                      )
                    }

                    <h5 className="section-title"><span>Диапазон цен</span></h5>

                    <div className="row justify-content-evenly"> 
                    <div className="col-1 m-1 mt-2"> 
                          От:
                      </div> 
                      <div className="col"> 
                          <input type="number" value={minprice} className="form-control" onChange={e => setMinprice(e.target.value)}/>
                      </div> 
                      <div className="col-1 m-1 mt-2"> 
                          До:
                      </div> 
                      <div className="col"> 
                          <input type="number" value={maxprice} className="form-control" onChange={e => setMaxprice(e.target.value)}/>
                      </div> 
                      
                  </div> 


                   
                    {/* <div className='row'>
                      <input value={minprice} className="form-control" name="priceRange" id="priceRange"/>
                      <input value={maxprice} className="form-control" name="priceRange" id="priceRange"/>

                    </div> */}
                    




                    <button onClick={filterClick} className="btn btn-primary mt-3">Применить
                    </button>

                    



                  </div>
                {/* </form> */}
                



              </div>

            </div>




          </div>
        </div>
        <div className="col-lg-9 col-md-8">
          <div className="row mb-3">
            <div className="col-12">
              <h1 className="section-title h3">
                <span>{catname}</span>
              </h1>
            </div>


          <div className="row">
          {
          products.map((item) => 
              <div className="col-xl-4 col-lg-6 mb-3" key={item.id}>
                <MiniCard prodid={item.id} price={item.price} title={item.title} image_url={item.image_url} count={item.count}/>
              </div>
              
            )
          }
          </div>

          

          {/* {
            products.map((item) => 
              <div className="col-xl-3 col-lg-4 col-md-6 mb-3">
                <MiniCard />
              </div>
              
            )
          } */}

          {/* <div className="row" v-if="pagination.last_page > 1">
            <div className="col-12">
              <nav aria-label="Пример навигации по страницам">
                <ul className="pagination">
                  <li v-if="pagination.current_page !== 1" className="page-item">
                    <a @click.prevent="getProducts(pagination.current_page - 1)" className="page-link" href="#">Предыдущая</a>
                  </li>
                  <li v-for="link in pagination.links" className="page-item">
                    <template v-if="Number(link.label) &&
                    (pagination.current_page - link.label < 2 &&
                    pagination.current_page - link.label > -2) ||
                    Number(link.label) === 1 || Number(link.label) === pagination.last_page">
                      <a @click.prevent="getProducts(link.label)" :className="link.active ? 'active' : ''" className="page-link"
                         href="#">{{ link.label }}</a>
                    </template>
                    <template v-if="Number(link.label) &&
                  pagination.current_page !== 3 &&
                    (pagination.current_page - link.label === 2) ||
Number(link.label) &&
pagination.current_page !== pagination.last_page - 2 &&
                    (pagination.current_page - link.label === -2)

">
                      <a className="page-link">...</a>
                    </template>
                  </li>
<!--                  <li className="page-item active"><a className="page-link" href="#">1</a></li>-->

<!--                  -->
<!--                  <li className="page-item"><a className="page-link" href="#">2</a></li>-->
<!--                  <li className="page-item"><a className="page-link" href="#">3</a></li>-->
                  <li v-if="pagination.current_page !== pagination.last_page" className="page-item">
                    <a className="page-link" href="#">Следующая</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}


        </div>
      </div>
    </div>
    </div>
    </>
    
  )
}

export default Products