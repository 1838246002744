import React from 'react'
import { Link } from 'react-router-dom';

function MiniCard(props) {
  const { id, price, title, image_url, prodid } = props

  return (
    <div className="product-card">
              <div className="product-card-offer">
                {/* <div className="offer-hit">Hit</div> */}
                {/* <!--                <div className="offer-new">New</div>--> */}
              </div>
              <div className="product-thumb">
                <Link to={`/datails/${prodid}`}><img src={image_url} alt=""/> </Link>
              </div>
              <div className="product-details">
                <h4>
                <Link to={`/datails/${prodid}`}>{ title }</Link>
                </h4>
                {/* <p className="product-excerpt">
                  {title} 
                </p> */}
                <div className="product-bottom-details d-flex justify-content-between">
                  <div className="product-price">
                    {/* <!--                    <small>700p.</small>--> */}
                    {price} р.
                  </div>
                  <div className="product-links">
{/* <!--                    <a href="#" className="btn btn-outline-secondary add-to-cart">-->
<!--                      <i className="fas fa-shopping-cart"></i>-->
<!--                    </a>--> */}
                  </div>
                </div>
              </div>
            </div>
  )
}
export default MiniCard

