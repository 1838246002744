import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../assets/img/header-logo.jpg'

export default function Header() {
  return (
    <header className="header">
      <div className="header-top py-1">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="header-top-phone d-flex align-items-center h-100">
                <i className="fa-solid fa-phone header-i"></i>
                <a href="tel:+78435107504" className="ms-2 ">+7 (843) 510‒75‒04</a>
              </div>
            </div>
            <div className="col-md-4 mt-2 my-md-0">

              <p className="text-danger">Находясь на сайте вы подтверждаете что вам 18+</p>
            </div>
            <div className="col-md-4 mt-2 my-md-0">
              <ul className="social-icons d-flex justify-content-md-center">
                <li><a href="https://vk.com/harija" target="_blank" rel="noreferrer"><i className="fa-brands fa-vk"></i></a></li>
              </ul>

            </div>
          </div>
        </div>
      </div> 
      <div className="header-middle bg-white py-2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-6 col-lg-4">
              {/* <a href="#" className="header-logo"> */}
              <Link className="nav-link" to="/" ><img src={logo} width="150" alt="Логотип"/></Link>
                
              {/* </a> */}
            </div>

            <div className="col-sm-6 col-lg-4 order-md-1 mt-2 my-md-0">
              <form action="">
                <div className="input-group">
                  <input v-model="search" type="text" className="form-control" name="s" placeholder="Поиск"
                         aria-label="Поиск" aria-describedby="button-search"/>
                  <button className="btn btn-outline-primary" type="button" id="button-search"><i
                      className="fa-solid fa-magnifying-glass"></i></button>
                </div>
              </form>
            </div>

          </div>

        </div>
      </div>

      <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasCart" aria-labelledby="offcanvasCartLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasCartLabel">Корзина</h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                  aria-label="Закрыть"></button>
        </div>
        {/* <div className="offcanvas-body bg-white">
          <div className="table-responsive">
            <table className="table offcanvasCart-table">
              <tbody>
              <tr>
                <td className="product-image-td"><a href=""><img src="/src/assets/img/products/1.jpg" alt=""/></a></td>
                <td><a href="">Product 1 Lorem, ipsum dolor sit amet consectetur adipisicing elit.</a></td>
                <td>65 p.</td>
                <td>"times1"</td>
                <td>
                  <button className="btn btn-danger"><i className="fa-regular fa-circle-xmark"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td className="product-image-td"><a href=""><img
                    src="/src/assets/img/products/2.jpg" alt=""/></a></td>
                <td><a href="">Product 1 Lorem, ipsum dolor sit amet consectetur adipisicing
                  elit.</a></td>
                <td>100 p.</td>
                <td>times3</td>
                <td>
                  <button className="btn btn-danger"><i className="fa-regular fa-circle-xmark"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td className="product-image-td"><a href=""><img
                    src="/src/assets/img/products/3.jpg" alt=""/></a></td>
                <td><a href="">Product 1 Lorem, ipsum dolor sit amet consectetur adipisicing
                  elit.</a></td>
                <td>65 p.</td>
                <td>times1</td>
                <td>
                  <button className="btn btn-danger"><i className="fa-regular fa-circle-xmark"></i>
                  </button>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td colSpan="4" className="text-end">Итого:</td>
                <td>1200p.</td>
              </tr>
              </tfoot>
            </table>
          </div>
          <div className="text-end">
            <a href="" className="btn btn-primary">Корзина</a>
            <a href="" className="btn btn-primary">Оформить</a>
          </div>
        </div> */}
      </div>


    </header>


  )
}
