import React, { useEffect, useState } from 'react'
import Menu from '../components/Menu'
import img1 from '../assets/img/slider/1.jpg'
import img2 from '../assets/img/slider/2.jpg'
import img3 from '../assets/img/slider/3.jpg'
import MiniCard from '../components/MiniCard'
import axios from 'axios'
import Carousel from 'react-bootstrap/Carousel';


export default function Main() {
  const src = 'https://admin.kleversoft.ru/api/main';

  const [itemsHits, setItemsHits] = useState([]);
  const [itemsNews, setItemsNews] = useState([]);

  useEffect(() => {
    axios.get(src)
    .then(({data}) => {
       setItemsHits(data.hits)
       setItemsNews(data.news)
        console.log(data.news);
      
    }).catch(err => {
      // console.log(err);
      // Alert.alert('Ошибка', 'Не удалось подключится к серверу');
    }).finally(() => {
      // setIsLoading(false);
    });
  }, []);



  return (
    <>
    <Menu/>
    <div className="container">
    
      {/* <CCarousel controls indicators interval={5000}>
        <CCarouselItem>
          <CImage className="d-block w-100" src={img1} alt="slide 1" />
        </CCarouselItem>
        <CCarouselItem>
          <CImage className="d-block w-100" src={img2} alt="slide 2" />
        </CCarouselItem>
        <CCarouselItem>
          <CImage className="d-block w-100" src={img3} alt="slide 3" />
        </CCarouselItem>
      </CCarousel> */}
      <Carousel data-bs-theme="white" interval={5000}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={img1}
          alt="First slide"
        />
        <Carousel.Caption>
          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={img2}
          alt="Second slide"
        />
        <Carousel.Caption>
          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={img3}
          alt="Third slide"
        />
        <Carousel.Caption>
          
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

      </div>

      <section className="advantages d-none d-lg-block">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12">
            <h2 className="section-title">
              <span>Наши преимущества</span>
            </h2>
          </div>
        </div>


        <div className="row gy-3 items">
          <div className="col-lg-3 col-sm-6">
            <div className="item">
              <p>
                <i className="fas fa-shipping-fast"></i>
              </p>
              <p>Прямые поставки от производителей брендов</p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="item">
              <p>
                <i className="fas fa-cubes"></i>
              </p>
              <p>Широкий ассортимент товаров. Более 10 тыс. наименований</p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="item">
              <p>
                <i className="fas fa-hand-holding-usd"></i>
              </p>
              <p>Приятные и конкурентные цены</p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="item">
              <p>
                <i className="fa-solid fa-user-graduate"></i>
              </p>
              <p>Консультации от профессионалов</p>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section className="featured-product">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12">
            <h2 className="section-title">
              <span>Рекомендуемые товары</span>
            </h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">

          {
            itemsHits.map((item) => 
              <div className="col-xl-3 col-lg-4 col-md-6 mb-3" key={item.id}>
                <MiniCard prodid={item.id} price={item.price} title={item.title} image_url={item.image_url}/>
              </div>
              
            )
          }
        


        </div>
      </div>
    </section>

    <section className="featured-product">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12">
            <h2 className="section-title">
              <span>Новинки</span>
            </h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">

          {
            itemsNews.map((item) => 
              <div className="col-xl-3 col-lg-4 col-md-6 mb-3" key={item.id}>
                <MiniCard prodid={item.id} price={item.price} title={item.title} image_url={item.image_url}/>
              </div>
              
            )
          }
        
        </div>
      </div>
    </section>

    
      <div className="container">
      <iframe title="This is a unique title" id="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d559.7694131094448!2d49.08853936725511!3d55.86131912643759!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x41594d79ef991a99%3A0x91ccd518287d83c9!2z0KXQsNGA0LjRjw!5e0!3m2!1sru!2sru!4v1686911309154!5m2!1sru!2sru"
              width="100%" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

    </div>
        
    </>
  )
}
