import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import axios from 'axios'

function Menu() {
  const src = 'https://admin.kleversoft.ru/api/categories';

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    axios.get(src)
    .then(({data}) => {
      //  setItemsHits(data.hits)
        setCategories(data)
        console.log(data);
      
    }).catch(err => {
      // console.log(err);
      // Alert.alert('Ошибка', 'Не удалось подключится к серверу');
    }).finally(() => {
      // setIsLoading(false);
    });
  }, []);


  return (
    <>
    <div className="header-bottom sticky-top" id="header-nav">
    <nav className="navbar navbar-expand-lg bg-primary" data-bs-theme="dark">
      <div className="container">

        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false"
                aria-label="Переключатель навигации">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasNavbar"
             aria-labelledby="offcanvasNavbarLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">Каталог</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                    aria-label="Закрыть"></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/" >Главная</Link>
              </li>


                
                {categories.map((category) => 
                <li className="nav-item" key={category.id}>
                  <Link className="nav-link" to={`/products/${category.id}`} >{category.title}</Link>
                </li>
                  )         
                
                }


            </ul>
          </div>
        </div>
        <div className="cart-buttons d-block d-lg-none">

        </div>


      </div>
    </nav>
  </div>
    </>
  )
}

export default Menu