import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Footer from "./components/Footer.js";
import Header from "./components/Header.js";
import Main from "./views/Main.js";
import About from "./views/About.js";
import Policy from "./views/Policy.js"
import Products from './views/Products.js';
import Details from './views/Details.js';
import './'



function App() {
  return (
    <div className="wrapper">
      
      <BrowserRouter>
      <Header/>
        <main className="main">
        <Routes>
          <Route path="/policy" element={<Policy />} />
          <Route path="/" element={<Main />} />
          <Route path="/about" element={<About />} />
          <Route path="/products/:id" element={<Products />} />
          <Route path="/datails/:id" element={<Details />} />
        </Routes>
        </main>
      <Footer/>
    </BrowserRouter>

      
    </div>
    
  );
}

export default App;

